//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.sticky-top-size {
	top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
	--#{$prefix}border-opacity: 0;
}

// video input
.VideoInput_input {
  display: none;
	background-color: #000;
}

.VideoInput_video {
  display: block;
  margin: 0;
	background-color: #000;
}

.VideoInput_footer {
  width: 100%;
  min-height: 40px;
	padding: 15px;
  text-align: center;
}

.Videoinput_loader{
	position:absolute;
	top:0; 
	left:0; 
	right:0; 
	bottom:0; 
	background-color:#00000085;
	z-index: 999;
	justify-content: center;
	align-items: center;
	display: flex;

	.Videoinput_loader_icon{
		color: #fff;
	}
}


.dropdown-menu-custom{
  max-height: 300px !important;
	overflow: scroll;
}